<br><br>

<div>
      <div class="container">
        <div class="row">
          

        <div class="col-md-6 mt-5 tc" data-aos="fade-right">
            <h2 style="color: #347dbb;">¡Contáctanos!</h2>
            <hr class="hr-light">

    
            <h6 class="mb-3"> Si estas interesad@ en nuestros productos o servicios, incluso si quieres unirte al equipo Vivital 360 estamos atentos a tus solicitudes y valoramos tus preguntas.
              <br><br> ¡No dudes en contactarnos!
              </h6>

            <br>

            <ul class="list-unstyled">
              <li >
                <p><i class="fas fa-home mr-3 "></i> Plaza Aguascalientes local 25 Aguascalientes, México</p>
              </li>
              <li >
                <p><i class="fas fa-envelope mr-3"></i> com.emanuel@live.com.mx</p>
              </li>
              <li >
                <p><i class="fas fa-phone mr-3"></i>(CEL) +52 449 193 76 22</p>
                <p><i class="fas fa-phone mr-3"></i>(OF)  +52 449 917 95 11</p>
              </li>
            </ul>

        </div>
          

        <div class="col-md-6 mt-5 pb-5">
          <div class="embed-responsive embed-responsive-4by3" >
            <iframe  data-aos="fade-left" class="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d327.2548201753893!2d-102.28043874883024!3d21.878137994516855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ef5bf6101a89%3A0x3cdcce1c977aee3d!2sPlaza%20Aguascalientes!5e0!3m2!1ses-419!2smx!4v1608331169180!5m2!1ses-419!2smx" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>

          
            
            <!--
            <div class="card" style="background-color: rgb(94, 138, 219);">
              <div class="card-body">
               
                <div class="text-center">
                  <h2 class="white-text">
                     Envía tu mensaje</h2>
                  <hr class="hr-light">
                </div>
         
                <div>
                  <i class="fas fa-user prefix white-text active"></i>
                  <input type="text"  class="white-text form-control" placeholder="Nombre...">
                </div>
                <div>
                  <i class="fas fa-envelope prefix white-text active"></i>
                  <input type="email"  class="white-text form-control" placeholder="Correo electrónico">
                </div>
                <div>
                  <i class="fas fa-lock prefix white-text active"></i>
                  <input type="text" class="white-text form-control" placeholder="Mensaje...">
                </div>
                <div class="text-center mt-4">
                  <button class="btn btn-success rounded-pill">Enviar</button>
                </div>
              </div>
            </div>
            
          </div>
           -->
          
        </div>
     
      </div>
    <!-- Mask & flexbox options-->
  </div>

  <br><br><br><br><br>