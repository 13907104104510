<div>
  <img style="width: 100%; height: 100%;" src="../../assets/images/BANERS/BANNER PRODUCTOS AOCNDICIONADORES.png">
</div>

<div class="card-deck mt-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO11.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Keratina colágeno</h5>
      <a href="/Acondicionadores#p1"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO2.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Botox Keratine</h5>
      <a href="/Acondicionadores#p2"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top"  src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO3.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Anticaída</h5>
      <a href="/Acondicionadores#p3"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más </button></a>
    </div>
  </div>
</div>

<div class="card-deck mt-5 mb-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO4.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Colors</h5>
      <a href="/Acondicionadores#p4"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top"  src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO5.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Doble fase </h5>
      <a href="/Acondicionadores#p5"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top"  src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO6.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Doble fase </h5>
      <a href="/Acondicionadores#p6"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más </button></a>
    </div>
  </div>
</div>


<div class="card-deck mt-5 mb-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top"  src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO7.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Fórmula </h5>
      <a href="/Acondicionadores#p7"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top"  src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO8.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Loción </h5>
      <a href="/Acondicionadores#p8"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top"  src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO9.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Tratamiento Acondicionador</h5>
      <a href="/Acondicionadores#p9"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más </button></a>
    </div>
  </div>
</div>


<div class="card-deck mt-5 mb-5 col-lg-4 col-md-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top"  src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO10.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Placenta</h5>
      <a href="/Acondicionadores#p10"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más </button></a>
    </div>
  </div>

</div>



<hr>






<div id="p1" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO1.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000">
    <h2><strong>Keratina colágeno</strong> </h2>


      <p style="text-align: justify;">Tratamiento capilar de Alta Penetración y por consecuencia Altamente Eficaz en cualquier tipo de cabello para su restauración. 
      </p>
      <ul style="text-align: justify;">
        <li> Cabello reseco: quita lo esponjado, deja apariencia de humedad y mantiene el cabello firme, sin necesidad de utilizar mousse.</li>
        <li> Cabello teñido: renueva rápidamente el brillo y la sedosidad del cabello.</li>
        <li> Cabello chino: Define el chino y lo mantiene firme sin endurecerlo, quita lo esponjado y lo hidrata al mismo tiempo.</li>
      </ul>

      <p style="text-align: justify;">Ideal para proteger al cabello antes del planchado y moldeado con secadora. Así mismo cubre al cabello de deterioro por uso de aparatos electrónicos, procesos químicos,  medio ambiente, aguas mineralizadas, saladas y cloradas.
        <br>Su aplicación es en cabello húmedo o seco no se enjuaga y si se utiliza calor después de su aplicación, penetra más rápidamente en cutícula, provocando la suavidad y manejo del cabello al instante.
        <br>Excelente protector para la deshidratación en procesos de teñido y decoloración. Sellador de puntas.
      </p>
        

  </div>
</div>

<hr>

<div id="p2" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000">
    <h2><strong>Botox Keratine</strong> </h2>
   
      <p style="text-align: justify;">Tratamiento intensivo para cabellos altamente resecos y maltratados debido a la exposición de procesos y climas extremos tales como:
      </p>
      <ul style="text-align: justify;">
        <li>  Decoloraciones</li>
        <li> Teñidos sobre teñidos</li>
        <li> Alaciados permanentes mal aplicados </li>
        <li> Zonas desérticas y calurosas</li>
        <li> Playas y uso de albercas cotidianamente</li>
        <li> Aplicación en cabello húmedo o seco, de medios a puntas, “no retirar”. Utilice calor una vez que el cabello haya absorbido el producto, si así lo desea.</li>
      </ul>
      
  </div>
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO2.png" alt="">
  </div>
</div>

<hr>

<div id="p3" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO3.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000">
    <h2><strong>Anticaída</strong> </h2>
    <p>Loción auxiliar para la caída de cabello. 
      <br> Se aplica en húmedo de raíz a medios dando ligero masaje sin enjuagar.
      </p>
  </div>
</div>

<hr>

<div id="p4" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000">
    <h2><strong>Colors</strong> </h2>
    <p>Crema revitalizante para cabello teñido maltratado o reseco.
      <br> Ideal para sellar cutícula despúes del tinte.
      <br> Enjuagar.
      </p>

  </div>
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO4.png" alt="">
  </div>
</div>

<hr>

<div id="p5" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO5.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000">
    <h2><strong>Doble fase </strong> </h2>
    <p>Hidratante para cabello en proceso, auxiliar en el planchado y desenredante.
      <br>  Con antioxidantes.
      </p>
  </div>
</div>

<hr>

<div id="p6" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000">
    <h2><strong>Doble fase </strong> </h2>
    <p>Hidratante para cabello en proceso, auxiliar en el planchado y desenredante.
      <br>  Con antioxidantes.
      </p>
  </div>
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO6.png" alt="">
  </div>
</div>

<hr>

<div id="p7" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO7.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000">
    <h2><strong>Fórmula </strong> </h2>
    <p>Tratamiento en crema para cabello Ultra seco por proceso químico o contacto con el medio ambiente, como tierra, esmog o climas secos, quita volumen.</p>
  </div>
</div>

<hr>

<div id="p8" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000">
    <h2><strong>Loción </strong> </h2>
    <p>Tratamiento en líquido para cabello Ultra Seco por proceso químico o contacto con el medio ambiente, como tierra, esmog o climas secos, quita volumen.</p>

  </div>
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO8.png" alt="">
  </div>
</div>

<hr>

<div id="p9" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO9.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000">
    <h2><strong>Tratamiento Acondicionador</strong> </h2>
    <p>Fórmula Super Activa Enriquecida con polyquartenium 7. Integrador que encapsula los ingredientes del tratamiento en la fibra capilar, otorgando en el momento suavidad, brillo y manejabilidad. Excelentes resultados en cabellos resecos y en proceso constante, anti-sponge, y se puede usar como crema peinadora, puede combinarse en la mezcla de tinte y el decolorante con peróxido para proteger al cabello de la deshidratación del proceso.</p>
  </div>
</div>

<hr>

<div id="p10" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000">
    <h2><strong>Placenta</strong> </h2>
    <p>Reconstructor instantáneo para cabello trozado por decoloración o solución permanente, débil, quebradizo, delgado y en constante proceso.
      <br> Auxiliar en planchado evitando la deshidratación del cabello y el vapor o humo.
      <br> Auxiliar en la caída del cabello.
      </p>

  </div>
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/ACONDICIONADORES/ACONDICIONADORES PRODUCTO10.png" alt="">
  </div>
</div>

