import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-naturales',
  templateUrl: './naturales.component.html',
  styleUrls: ['./naturales.component.css']
})
export class NaturalesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
