<!--
<div>
    <img style="width: 100%; height: 100%;" src="../../assets/images/BANERS/BANNER PRODUCTOS GELES CAPILARES.png">
  </div>
  
  <div class="card-deck mt-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/GELES/GELES PRODUCTO1.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Producto 1</h5>
        <h6>$200</h6>
        <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
          data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"> Ver más </button>
        <div class="collapse" id="collapseExample">
          <div class="card card-body border-0 ct">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
          </div>
        </div>
      </div>
    </div>
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/GELES/GELES PRODUCTO2.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Producto 2</h5>
        <h6>$200</h6>
        <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
          data-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample2"> Ver más </button>
        <div class="collapse" id="collapseExample2">
          <div class="card card-body border-0 ct">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
          </div>
        </div>
      </div>
    </div>
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/GELES/GELES PRODUCTO3.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Producto 3</h5>
        <h6>$200</h6>
        <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
          data-target="#collapseExample3" aria-expanded="false" aria-controls="collapseExample3"> Ver más </button>
        <div class="collapse" id="collapseExample3">
          <div class="card card-body border-0 ct">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="card-deck mt-5 mb-5 col-lg-8 col-md-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/GELES/GELES PRODUCTO4.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Producto 4</h5>
        <h6>$200</h6>
        <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
          data-target="#collapseExample4" aria-expanded="false" aria-controls="collapseExample4"> Ver más </button>
        <div class="collapse" id="collapseExample4">
          <div class="card card-body border-0 ct">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
          </div>
        </div>
      </div>
    </div>
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/GELES/GELES PRODUCTO5.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Producto 5</h5>
        <h6>$200</h6>
        <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
          data-target="#collapseExample5" aria-expanded="false" aria-controls="collapseExample5"> Ver más </button>
        <div class="collapse" id="collapseExample5">
          <div class="card card-body border-0 ct">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
          </div>
        </div>
      </div>
    </div>
  </div>
  -->


<div>
  <img style="width: 100%; height: 100%;" src="../../assets/images/BANERS/BANNER PRODUCTOS GELES CAPILARES.png">
</div>

<div class="card-deck mt-5 col-lg-8 col-md-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/GELES/GELES PRODUCTO2.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Gel Extra fijación</h5>
      <a href="/GelesCapilares#p2"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/GELES/GELES PRODUCTO6.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Gel modelador</h5>
      <a href="/GelesCapilares#p6"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>

</div>










<hr>


<div id="p2" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Gel Extra fijación</strong> </h2>
    <p>Creado para las necesidades del hombre, con fragancia varonil y elementos anticaída, dando sedosidad a cualquier
      tipo de cabello sin dejar residuos. Libre de alcohol.</p>
  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/GELES/GELES PRODUCTO2.png" alt="">
  </div>
</div>

<hr>

<div id="p6" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/GELES/GELES PRODUCTO6.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Gel modelador</strong> </h2>
    <p>Aspecto húmedo, gran fijación, libre de alcohol por lo que no deja residuos blancos. <br>
      No maltrata el cabello.</p>
  </div>
</div>
