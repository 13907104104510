import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-decoloracion',
  templateUrl: './decoloracion.component.html',
  styleUrls: ['./decoloracion.component.css']
})
export class DecoloracionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
