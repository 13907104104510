import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CheckboxRequiredValidator, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  public form: any;

  constructor(private formBuilder: FormBuilder, private router: Router ) {
    this.form = formBuilder.group({
      pagina: ['', [Validators.required]],
    });
   }

  ngOnInit(): void {
  }

  submitBuscar(){
      if(this.form.get('pagina').value == "Home"  || this.form.get('pagina').value == "home" || this.form.get('pagina').value == "HOME" || this.form.get('pagina').value == "Inicio"  || this.form.get('pagina').value == "inicio" || this.form.get('pagina').value == "INICIO" ){
        this.router.navigate(['/home']);
      }
      else if(this.form.get('pagina').value == "Distribuidores" || this.form.get('pagina').value == "distribuidores" || this.form.get('pagina').value == "DISTRIBUIDORES"){
        this.router.navigate(['/distribuidores']);
      }
      else if(this.form.get('pagina').value == "Contacto" || this.form.get('pagina').value == "contacto" || this.form.get('pagina').value == "CONTACTO"){
        this.router.navigate(['/contacto']);
      }
      else if(this.form.get('pagina').value == "Silicas" || this.form.get('pagina').value == "SILICAS" || this.form.get('pagina').value == "silicas"){
        this.router.navigate(['/Silikas']);
      }
      else if(this.form.get('pagina').value == "Oleos" || this.form.get('pagina').value == "OLEOS" || this.form.get('pagina').value == "oleos"){
        this.router.navigate(['/Oleos']);
      }
      else if(this.form.get('pagina').value == "Naturales" || this.form.get('pagina').value == "NATURALES" || this.form.get('pagina').value == "naturales"){
        this.router.navigate(['/Naturales']);
      }
      else if(this.form.get('pagina').value == "Matizadores" || this.form.get('pagina').value == "MATIZADORES" || this.form.get('pagina').value == "matizadores"){
        this.router.navigate(['/Matizadores']);
      }
      else if(this.form.get('pagina').value == "Decoloracion" || this.form.get('pagina').value == "DECOLORACION" || this.form.get('pagina').value == "decoloracion"){
        this.router.navigate(['/Decoloracion']);
      }
      else if(this.form.get('pagina').value == "Acondicionadores" || this.form.get('pagina').value == "ACONDICIONADORES" || this.form.get('pagina').value == "acondicionadores"){
        this.router.navigate(['/Acondicionadores']);
      }
      else if(this.form.get('pagina').value == "Geles Capilares" || this.form.get('pagina').value == "GELES CAPILARES" || this.form.get('pagina').value == "geles capilares" || this.form.get('pagina').value == "Geles" || this.form.get('pagina').value == "geles" || this.form.get('pagina').value == "GELES"){
        this.router.navigate(['/GelesCapilares']);
      }
      else if(this.form.get('pagina').value == "Fashion Line" || this.form.get('pagina').value == "FASHION LINE" || this.form.get('pagina').value == "fashion line" || this.form.get('pagina').value == "Fashion" || this.form.get('pagina').value == "fashion" || this.form.get('pagina').value == "GELES"){
        this.router.navigate(['/FashionLine']);
      }
      else if(this.form.get('pagina').value == "Nicte-Ha" || this.form.get('pagina').value == "Nicte Ha" || this.form.get('pagina').value == "NICTE HA" || this.form.get('pagina').value == "NICTE-HA" || this.form.get('pagina').value == "Nicte" || this.form.get('pagina').value == "nicte ha"){
        this.router.navigate(['/FashionLine']);
      }
      
    }
  }

