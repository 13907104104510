<div>
<img style="width: 100%; height: 100%;" src="../../assets/images/BANERS/BANNER PRODUCTOS SILIKAS.png" > 
</div>

<!--
 PRIMERA OPCION 
<div class="card-deck mt-5 col-12 text-center">
    <div class="card">
      <img class="card-img-top" src="../../assets/images/SILIKAS PRODUCTO1.png" alt="Card image cap">
      <div class="card-body">
        <h5 class="card-title">Producto 1</h5>
        <button class="btn btn-primary rounded-pill text-center" >Ver más </button>
      </div>
    </div>
    <div class="card">
      <img class="card-img-top" src="../../assets/images/SILIKAS PRODUCTO2.png" alt="Card image cap">
      <div class="card-body">
        <h5 class="card-title">Producto 2</h5>
        <button class="btn btn-primary rounded-pill text-center" >Ver más </button>
      </div>
    </div>
    <div class="card">
      <img class="card-img-top" src="../../assets/images/SILIKAS PRODUCTO3.png" alt="Card image cap">
      <div class="card-body">
        <h5 class="card-title">Producto 3</h5>
        <button class="btn btn-primary rounded-pill text-center" >Ver más </button>
      </div>
    </div>
    <div class="card">
        <img class="card-img-top" src="../../assets/images/SILIKAS PRODUCTO4.png" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title">Producto 4</h5>
          <button class="btn btn-primary rounded-pill text-center" >Ver más </button>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="../../assets/images/SILIKAS PRODUCTO5.png" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title">Producto 5</h5>
          <button class="btn btn-primary rounded-pill text-center" >Ver más </button>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="../../assets/images/SILIKAS PRODUCTO6.png" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title">Producto 6</h5>
          <button class="btn btn-primary rounded-pill text-center" >Ver más </button>
        </div>
      </div>
</div>



TERCERA OPCION 

<div class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5">
      <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SILIKAS PRODUCTO1.png" alt="">
  </div>
  <div class="col-sm-5 text-center">
      <h2><strong>Producto 1</strong> </h2>
      <p >Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus cumque qui reiciendis perferendis voluptate nisi ratione laborum exercitationem, doloremque, modi architecto, odio adipisci eaque. Earum voluptates dolores eos similique autem?Asperiores tempore unde iure velit. Ducimus dolor perferendis quisquam adipisci modi alias maxime ipsam accusantium minus, quia corporis quibusdam illo. Assumenda vel distinctio quaerat omnis hic, rem laborum recusandae molestias?</p>
      
  </div>
</div>


<div class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center">
      <h2><strong>Producto 2</strong> </h2>
      <p >Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus cumque qui reiciendis perferendis voluptate nisi ratione laborum exercitationem, doloremque, modi architecto, odio adipisci eaque. Earum voluptates dolores eos similique autem?Asperiores tempore unde iure velit. Ducimus dolor perferendis quisquam adipisci modi alias maxime ipsam accusantium minus, quia corporis quibusdam illo. Assumenda vel distinctio quaerat omnis hic, rem laborum recusandae molestias?</p>
  </div>
  <div class="col-sm-5">
      <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SILIKAS PRODUCTO2.png" alt="">
  </div>
</div>


<div class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5">
      <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SILIKAS PRODUCTO3.png" alt="">
  </div>
  <div class="col-sm-5 text-center">
      <h2><strong>Producto 3</strong> </h2>
      <p >Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus cumque qui reiciendis perferendis voluptate nisi ratione laborum exercitationem, doloremque, modi architecto, odio adipisci eaque. Earum voluptates dolores eos similique autem?Asperiores tempore unde iure velit. Ducimus dolor perferendis quisquam adipisci modi alias maxime ipsam accusantium minus, quia corporis quibusdam illo. Assumenda vel distinctio quaerat omnis hic, rem laborum recusandae molestias?</p>
  </div>
</div>


<div class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center">
      <h2><strong>Producto 4</strong> </h2>
      <p >Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus cumque qui reiciendis perferendis voluptate nisi ratione laborum exercitationem, doloremque, modi architecto, odio adipisci eaque. Earum voluptates dolores eos similique autem?Asperiores tempore unde iure velit. Ducimus dolor perferendis quisquam adipisci modi alias maxime ipsam accusantium minus, quia corporis quibusdam illo. Assumenda vel distinctio quaerat omnis hic, rem laborum recusandae molestias?</p>
     
  </div>
  <div class="col-sm-5">
      <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SILIKAS PRODUCTO4.png" alt="">
  </div>
</div>


<div class="row justify-content-center align-items-center m-5">
<div class="col-sm-5">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SILIKAS PRODUCTO5.png" alt="">
</div>
<div class="col-sm-5 text-center">
    <h2><strong>Producto 5</strong> </h2>
    <p >Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus cumque qui reiciendis perferendis voluptate nisi ratione laborum exercitationem, doloremque, modi architecto, odio adipisci eaque. Earum voluptates dolores eos similique autem?Asperiores tempore unde iure velit. Ducimus dolor perferendis quisquam adipisci modi alias maxime ipsam accusantium minus, quia corporis quibusdam illo. Assumenda vel distinctio quaerat omnis hic, rem laborum recusandae molestias?</p>
</div>
</div>


<div class="row justify-content-center align-items-center m-5">
<div class="col-sm-5 text-center">
    <h2><strong>Producto 6</strong> </h2>
    <p >Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus cumque qui reiciendis perferendis voluptate nisi ratione laborum exercitationem, doloremque, modi architecto, odio adipisci eaque. Earum voluptates dolores eos similique autem?Asperiores tempore unde iure velit. Ducimus dolor perferendis quisquam adipisci modi alias maxime ipsam accusantium minus, quia corporis quibusdam illo. Assumenda vel distinctio quaerat omnis hic, rem laborum recusandae molestias?</p>
   
</div>
<div class="col-sm-5">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SILIKAS PRODUCTO6.png" alt="">
</div>
</div>

-->





<!-- SEGUNDA OPCION-->
<div class="card-deck mt-5 col-12 text-center" data-aos="zoom-in"  data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
    <img class="card-img-top" src="../../assets/images/SILKAS/SILIKAS PRODUCTO1.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Producto 1</h5>
      <h6>$200</h6>
      <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"> Ver más </button>
      <div class="collapse" id="collapseExample">
        <div class="card card-body border-0 ct">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
        </div>
      </div>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
    <img class="card-img-top" src="../../assets/images/SILKAS/SILIKAS PRODUCTO2.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Producto 2</h5>
      <h6>$200</h6>
      <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse" data-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample2"> Ver más </button>
      <div class="collapse" id="collapseExample2">
        <div class="card card-body border-0 ct">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
        </div>
      </div>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
    <img class="card-img-top" src="../../assets/images/SILKAS/SILIKAS PRODUCTO3.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Producto 3</h5>
      <h6>$200</h6>
      <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse" data-target="#collapseExample3" aria-expanded="false" aria-controls="collapseExample3"> Ver más </button>
      <div class="collapse" id="collapseExample3">
        <div class="card card-body border-0 ct">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card-deck mt-5 mb-5 col-12 text-center" data-aos="zoom-in"  data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
    <img class="card-img-top" src="../../assets/images/SILKAS/SILIKAS PRODUCTO4.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Producto 4</h5>
      <h6>$200</h6>
      <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse" data-target="#collapseExample4" aria-expanded="false" aria-controls="collapseExample4"> Ver más </button>
      <div class="collapse" id="collapseExample4">
        <div class="card card-body border-0 ct">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
        </div>
      </div>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
    <img class="card-img-top" src="../../assets/images/SILKAS/SILIKAS PRODUCTO5.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Producto 5</h5>
      <h6>$200</h6>
      <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse" data-target="#collapseExample5" aria-expanded="false" aria-controls="collapseExample5"> Ver más </button>
      <div class="collapse" id="collapseExample5">
        <div class="card card-body border-0 ct">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
        </div>
      </div>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
    <img class="card-img-top" src="../../assets/images/SILKAS/SILIKAS PRODUCTO6.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Producto 6</h5>
      <h5>$200</h5>
      <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse" data-target="#collapseExample6" aria-expanded="false" aria-controls="collapseExample6"> Ver más </button>
      <div class="collapse" id="collapseExample6">
        <div class="card card-body border-0 ct">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
        </div>
      </div>
    </div>
  </div>
  
</div>



