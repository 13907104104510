<footer class="pf pt-4" style="background-color: #2353d9;">

    <div class="container-fluid text-center text-md-left">
      <div class="row">
        
       
        <div class="nave col-md-4 mb-md-0 mb-3 text-center">
          <h5 class="text-white">Navegación</h5>
  
          <ul class="list-unstyled text-white">
            <li>
              <a class="text-white" [routerLink]="['/distribuidores']" >Distribuidores</a>
            </li>
            <li>
              <a class="text-white" href="#qs" >¿Quiénes Somos?</a>
            </li>
            <li>
              <a class="text-white"  [routerLink]="['/contacto']">Contacto</a>
            </li>
          </ul>
        </div>
  
  
        
        <div class="logo col-md-4 mt-md-0 mt-3 text-center">
          <a class="mx-auto" href="#">
              <img src="../../assets/images/LOGO VIVITAL BLANCO (1).png" width="110" height="73">
          </a>
            <!-- <p class="text-white">Lema</p> -->
        </div>
  
  
  
  
        <div class="nave col-md-4 mb-md-0 mb-3 text-center">
          <h5 class="text-white">Síguenos en</h5>
         <ul class="list-unstyled">
          <li class="mt-3">
            <a href="https://www.facebook.com/Vivitalproductos" target="_balnk"><i style="color: white;" class="mr-2 fab fa-facebook fa-2x "></i></a>
          </li>
          <li class="mt-3">
           <a href="https://www.instagram.com/Vivital_oficial/" target="_blank"><i style="color: white;" class="mr-2 fab fa-instagram fa-2x"></i></a> 
          </li>
         </ul>
       </div>
     
        <!-- 
        <div class="col-md-3 mb-md-0 mb-3 text-center">
           <h5 class="text-uppercase text-white">Contáctanos</h5>
          <ul class="list-unstyled">
            <li >
              <p class="text-white"><i class="fas fa-home mr-3 "></i> Direccion</p>
            </li>
            <li >
              <p class="text-white"><i class="fas fa-envelope mr-3"></i> correo@example.com</p>
            </li>
            <li >
              <p class="text-white"><i class="fas fa-phone mr-3"></i> 4499999999</p>
            </li>
          </ul>
        </div>
        -->
  
      </div>
    </div>
  
    <div style="background-color:#12327e" class="footer-copyright text-center py-3 text-white pb-3">© 2020 Copyright: Vivital360.com</div>
  </footer>
  