<div>
  <img style="width: 100%; height: 100%;" src="../../assets/images/BANERS/BANNER OLEOS.png">
</div>

<div class="card-deck mt-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/OLEOS/OLEOS PRODUCTO1.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Oleo Almendras</h5>
      <a href="/Oleos#p1"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/OLEOS/OLEOS PRODUCTO2.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Oleo Macadamia</h5>
      <a href="/Oleos#p2"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/OLEOS/OLEOS PRODUCTO3.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Oleo Te Tree</h5>
      <a href="/Oleos#p3"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
</div>

<div class="card-deck mt-5 mb-5 col-lg-8 col-md-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/OLEOS/OLEOS PRODUCTO4.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Oleo Argán</h5>
      <a href="/Oleos#p4"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/OLEOS/OLEOS PRODUCTO5.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Oleo Pepita de uva</h5>
      <a href="/Oleos#p5"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>

</div>



<hr>




<div id="p1" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/OLEOS/OLEOS PRODUCTO1.png">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Oleo Almendras</strong> </h2>
    <p>El Óleo de almendras es ANTICASPA, tiene importantes nutrientes, incluyendo ácidos poli insaturados y grasos.
      Además de vitaminas E, D, B1, B2, B6 y A. Todos ellos son nutrientes beneficiosos que le permitirán a tu cabello
      crecer fuerte y saludable. Contiene una alta concentración de vitaminas A, B y E, siendo éstas, nutrientes
      importantes a la hora de hidratar y nutrir el cabello.
    </p>
    <ul>
      <li> El cabello crece rápidamente y se fortalece. Previene la caída del cabello mediante una mejora de la
        circulación
        sanguínea.</li>
      <li>
        El Óleo de almendras es fundamental mente adecuado para el cabello grueso, seco o dañado por el sol y por
        lostintes. Además, este tratamiento ayuda a definir los rizos.
      </li>
      <li>
        Eliminará mechones rebeldes, además de conseguir un toque extra de brillo.
      </li>
    </ul>
  </div>
</div>

<hr>


<div id="p2" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Oleo Macadamia</strong> </h2>
    <p>La Macadamia es un tipo de nuez y su aceite tiene excelentes propiedades. Es el único aceite vegetal que contiene
      ácido palmitoleico además de vitamina E y esteroles; que tienen propiedades suavizantes y calmantes que protegen
      nuestras fibras capilares de los agentes externos. Además, la nuez de Macadamia es, después del espino amarillo,
      el fruto con mayor cantidad de omega 7. Cuando envejece el cuerpo, nos niveles de lípidos bajan y tienen que ser
      sustituidos, por esa razón Oleo’s de Macadamia beneficia tanto, ya que actúa como el sebo del cuero cabelludo y se
      absorbe muy fácilmente.</p>
  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/OLEOS/OLEOS PRODUCTO2.png">
  </div>
</div>

<hr>

<div id="p3" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/OLEOS/OLEOS PRODUCTO3.png">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Oleo Te Tree</strong> </h2>
    <p>Oleo´s Te Tree contiene los siguientes componentes activos: </p>
    <ul>
      <li> Los terpenoides. Le confieren sus propiedades antimicrobianas.</li>
      <li> Aceites esenciales: el eucalipto y el 8-cineol, y le otorgan propiedades antisépticas, antibacterianas y
        antimicóticas. </li>
      <li> Puede detener la caspa. </li>
      <li> Previene la caída del cabello. </li>
      <li> Desobstruye los folículos pilosos. </li>
      <li> Elimina las partículas que están atrapadas en los folículos, como la piel muerta. </li>
      <li> Nutre las raíces del cabello, su toxicidad es nula. Es recomendado por muchos dermatólogos y peluqueros.
      </li>
      <li> Repele los piojos.</li>
    </ul>
  </div>
</div>

<hr>

<div id="p4" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Oleo Argán</strong> </h2>
    <p>Este Oleo proviene del extracto de las semillas del árbol de Argán, que crece en el suroeste de marruecos. Es un
      ingrediente raro por la limitación de su cultivo y porque el árbol tarda décadas en dar frutos.
      <br>
      El Óleo de Argán tiene vitaminas, minerales y ácidos grasos altamente beneficiosos para el cabello, reparando el
      daño en pocas aplicaciones. Otorga hidratación reduce la sequedad, da un alto brillo y elimina el freeze.
    </p>
  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/OLEOS/OLEOS PRODUCTO4.png" alt="">
  </div>
</div>

<hr>


<div id="p5" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/OLEOS/OLEOS PRODUCTO5.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Oleo Pepita de uva</strong> </h2>
    <p>El Óleo de Pepita de Uva de Vivital 360 es un efectivo hidratante y acondicionador natural para el cabello.</p>
    <ul>
      <li>Trata problemas como el cabello debilitado, la caída del cabello y la caspa.</li>
      <li>Excelente suplemento de tus productos regulares de caída del cabello, ¡Lo harán más fuerte y atractivo!</li>
      <li>Es liviano, no deja graso el cabello y es de absorción inmediata.      </li>
      <li>Ayuda a atrapar la humedad, evita el encrespado, sella puntas abiertas y reduce la dermatitis.</li>
      <li>Antioxidante.</li>
    </ul>  
  </div>
</div>
