import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { ProductosComponent } from './productos/productos.component';
import { ContactoComponent } from './contacto/contacto.component';
import { FooterComponent } from './footer/footer.component';
import { DistribuidoresComponent } from './distribuidores/distribuidores.component';
import { SilikasComponent } from './silikas/silikas.component';
import { OleosComponent } from './oleos/oleos.component';
import { NaturalesComponent } from './naturales/naturales.component';
import { MatizadoresComponent } from './matizadores/matizadores.component';
import { DecoloracionComponent } from './decoloracion/decoloracion.component';
import { AcondicionadoresComponent } from './acondicionadores/acondicionadores.component';
import { FashionComponent } from './fashion/fashion.component';
import { GelesComponent } from './geles/geles.component';
import { NicTeaComponent } from './nic-tea/nic-tea.component';
import { ShampoosComponent } from './shampoos/shampoos.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    ProductosComponent,
    ContactoComponent,
    FooterComponent,
    DistribuidoresComponent,
    SilikasComponent,
    OleosComponent,
    NaturalesComponent,
    MatizadoresComponent,
    DecoloracionComponent,
    AcondicionadoresComponent,
    FashionComponent,
    GelesComponent,
    NicTeaComponent,
    ShampoosComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
