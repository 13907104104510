<!--
<div>
    <img style="width: 100%; height: 100%;" src="../../assets/images/BANERS/BANNER PRODUCTOS MATIZADORES.png">
  </div>
  
  <div class="card-deck mt-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/MATIZADORES/MATIZADORES PRODUCTO1.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Producto 1</h5>
        <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
          data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"> Ver más </button>
        <div class="collapse" id="collapseExample">
          <div class="card card-body border-0 ct">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
          </div>
        </div>
      </div>
    </div>
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/MATIZADORES/MATIZADORES PRODUCTO2.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Producto 2</h5>
        <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
          data-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample2"> Ver más </button>
        <div class="collapse" id="collapseExample2">
          <div class="card card-body border-0 ct">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
          </div>
        </div>
      </div>
    </div>
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/MATIZADORES/MATIZADORES PRODUCTO3.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Producto 3</h5>
        <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
          data-target="#collapseExample3" aria-expanded="false" aria-controls="collapseExample3"> Ver más </button>
        <div class="collapse" id="collapseExample3">
          <div class="card card-body border-0 ct">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="card-deck mt-5 mb-5 col-lg-4 col-md-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/MATIZADORES/MATIZADORES PRODUCTO4.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Producto 4</h5>
        <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
          data-target="#collapseExample4" aria-expanded="false" aria-controls="collapseExample4"> Ver más </button>
        <div class="collapse" id="collapseExample4">
          <div class="card card-body border-0 ct">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
          </div>
        </div>
      </div>
    </div>
  
  </div>
  
  -->


  <div>
    <img style="width: 100%; height: 100%;" src="../../assets/images/BANERS/BANNER PRODUCTOS MATIZADORES.png">
  </div>
  
  <div class="card-deck mt-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/MATIZADORES/MATIZADORES PRODUCTO1.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Black</h5>
        <a href="/Matizadores#p1"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
      </div>
    </div>
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/MATIZADORES/MATIZADORES PRODUCTO2.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Violet</h5>
        <a href="/Matizadores#p2"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
      </div>
    </div>
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/MATIZADORES/MATIZADORES PRODUCTO3.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Blue</h5>
        <a href="/Matizadores#p3"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
      </div>
    </div>
  </div>
  
  <div class="card-deck mt-5 mb-5 col-lg-4 col-md-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/MATIZADORES/MATIZADORES PRODUCTO4.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Red</h5>
        <a href="/Matizadores#p4"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
      </div>
    </div>
  
  </div>






  <hr>






<div id="p1" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/MATIZADORES/MATIZADORES PRODUCTO1.png" >
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000">
    <h2><strong>Black</strong> </h2>
    <p>Oscurece de manera paulatina la cana. En cabello teñido y mechas previene la pérdida de color en cualquier tono de la gama y evita los visos del deslave.</p>

  </div>
</div>

<hr>


<div id="p2" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000" >
    <h2><strong>Violet</strong> </h2>
    <p>Elimina los visos amarillos en canas, mechas y rayos de tonos beige, dorados y rubios.</p>
  </div>
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/MATIZADORES/MATIZADORES PRODUCTO2.png" alt="">
  </div>
</div>

<hr>

<div id="p3" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/MATIZADORES/MATIZADORES PRODUCTO3.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000">
    <h2><strong>Blue</strong> </h2>
    <p>Elimina los visos naranjas o cobres en mechas platinadas.</p>
  </div>
</div>

<hr>

<div id="p4" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000">
    <h2><strong>Red</strong> </h2>
    <p>Para su uso en cabello teñido de rojo o mechas rojas, dando prolongación en su tono. Con gérmen de arroz.</p>

  </div>
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/MATIZADORES/MATIZADORES PRODUCTO4.png" alt="">
  </div>
</div>
