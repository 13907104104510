import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-geles',
  templateUrl: './geles.component.html',
  styleUrls: ['./geles.component.css']
})
export class GelesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
