<div class="text-center mx-auto mt-5 col-8">
  <h4 style="color: #347dbb">Contamos con un equipo amplio y dedicado en el territorio nacional para lograr un servicio
    que iguale la calidad de nuestros productos.</h4>
</div>

<div class="mt-5">
  <img style="width: 100%; height: 100%;" src="../../assets/images/Mapa-01.png">
</div>

<div class="mx-auto mt-5 col-8 mb-5">
    <h5 style="color: #347dbb">DISTRIBUIDORES</h5>
    <h5 class="rm" style="color: #347dbb"> <strong>REPÚBLICA MEXICANA</strong> </h5>
  <ul class="mt-3">
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Aguascalientes</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Baja California Norte</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Baja California Sur</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Chihuahua</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Colima</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Durango</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">EDO. De Mexico</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Guanajuato</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Hidalgo</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Jalisco</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Michoacán</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Morelos</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Nayarit</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Nuevo León</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Puebla</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Querétaro</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Quintana Roo</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">San Luis Potosí</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Sinaloa</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Tamaulipas</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Veracruz</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Yucatán</li>
    <li> <img src="../../assets/images/FAVICON.png" height="20px" width="20px">Zacatecas</li>
  </ul>
</div>
