<!--
<div>
  <img style="width: 100%; height: 100%;" src="../../assets/images/BANERS/BANNER PRODUCTOS NATURALE.png">
</div>

<div class="card-deck mt-5 col-md-12 col-lg-8 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NATURALES/NATURALES PRODUCTO1.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Producto 1</h5>
      <h6>$200</h6>
      <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
        data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"> Ver más </button>
      <div class="collapse" id="collapseExample">
        <div class="card card-body border-0 ct">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
          keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
        </div>
      </div>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NATURALES/NATURALES PRODUCTO2.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Producto 2</h5>
      <h6>$200</h6>
      <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
        data-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample2"> Ver más </button>
      <div class="collapse" id="collapseExample2">
        <div class="card card-body border-0 ct">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
          keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
        </div>
      </div>
    </div>
  </div>
</div>
-->


<div>
  <img style="width: 100%; height: 100%;" src="../../assets/images/BANERS/BANNER PRODUCTOS NATURALE.png">
</div>

<div class="card-deck mt-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NATURALES/NATURALES PRODUCTO1.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Crema Facial Concha nácar</h5>
      <a href="/Naturales#p1"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NATURALES/NATURALES PRODUCTO2.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Crema Facial Tamara</h5>
      <a href="/Naturales#p2"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NATURALES/NATURALES PRODUCTO3.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Gel y loción cubre canas</h5>
      <a href="/Naturales#p3"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
</div>


<div class="card-deck mt-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NATURALES/NATURALES PRODUCTO4.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Gel y loción cubre canas</h5>
      <a href="/Naturales#p4"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NATURALES/NATURALES PRODUCTO5.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Gel reductor</h5>
      <a href="/Naturales#p5"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NATURALES/NATURALES PRODUCTO6.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Esponja Callicida</h5>
      <a href="/Naturales#p6"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
</div>




<hr>




<div id="p1" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NATURALES/NATURALES PRODUCTO1.png">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Crema Facial Concha nácar</strong> </h2>
    <p>Crema Natural a base de Concha Nacar de aplicación de noche, quita manchas.</p>

  </div>
</div>

<hr>


<div id="p2" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Crema Facial Tamara</strong> </h2>
    <p>Aplica de noche, quita manchas hechas por el sol como paño y algunos productos faciales, así mismo aclara el
      rostro de manera paulatina, no es humectante por lo que tarda en ser absorbida y no es grasosa.
      Excelentes resultados para eliminar espinillas y barros y las cicatrices hechas por éstas.
    </p>
  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NATURALES/NATURALES PRODUCTO2.png">
  </div>
</div>


<hr>


<div id="p3" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NATURALES/NATURALES PRODUCTO3.png">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Gel y loción cubre canas</strong> </h2>
    <p>Va oscureciendo la cana virgen gradualmente hasta quedar en su color original, es de uso diario, no se enjuaga y
      de preferencia 2 veces al día en los primeros 15 días.
      No combinar con tintes.
    </p>

  </div>
</div>

<hr>


<div id="p4" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Gel y loción cubre canas</strong> </h2>
    <p>Va oscureciendo la cana virgen gradualmente hasta quedar en su color original, es de uso diario, no se enjuaga y
      de preferencia 2 veces al día en los primeros 15 días.
      No combinar con tintes.
    </p>
  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NATURALES/NATURALES PRODUCTO4.png">
  </div>
</div>


<hr>



<div id="p5" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NATURALES/NATURALES PRODUCTO5.png">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Gel reductor</strong> </h2>
    <p>Reafirma zonas flácidas, tonifica, tratamiento auxiliar para estrías y celulitis, de rápida absorción no mancha la ropa ni piel. 
      Así mismo no irrita la piel.
      <br>Excelente para personas a dieta o recién aliviadas.
    </p>
  </div>
</div>

<hr>



<div id="p6" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Esponja Callicida</strong> </h2>
    <p>Elimina callos y da suavidad a la planta de los pies. <br>
      El pie y el producto tienen que estar en contacto contínuo con el agua, ya que la esponja toma una estructura tipo
      lija contra los callos.</p>
  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NATURALES/NATURALES PRODUCTO6.png">
  </div>
</div>
