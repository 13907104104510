<!--
<div>
  <img style="width: 100%; height: 100%;" src="../../assets/images/BANERS/BANNER PRODUCTOS DECO.png">
</div>

<div class="card-deck mt-5 col-lg-8 col-md-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/DECOLORACION/DECOLORACION PRODUCTO1.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Producto 1</h5>
      <h6>$200</h6>
      <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
        data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"> Ver más </button>
      <div class="collapse" id="collapseExample">
        <div class="card card-body border-0 ct">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
          keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
        </div>
      </div>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/DECOLORACION/DECOLORACION PRODUCTO2.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Producto 2</h5>
      <h6>$200</h6>
      <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
        data-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample2"> Ver más </button>
      <div class="collapse" id="collapseExample2">
        <div class="card card-body border-0 ct">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
          keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
        </div>
      </div>
    </div>
  </div>
</div>
-->



<div>
  <img style="width: 100%; height: 100%;" src="../../assets/images/BANERS/BANNER PRODUCTOS DECO.png">
</div>

<div class="card-deck mt-5 col-lg-8 col-md-12 text-center">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/DECOLORACION/DECOLORACION PRODUCTO1.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Decolorante y Peróxido </h5>
      <a href="/Decoloracion#p1"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
      </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/DECOLORACION/DECOLORACION PRODUCTO2.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Decolorante y Peróxido </h5>
      <a href="/Decoloracion#p2"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
      </button></a>
    </div>
  </div>
</div>







<hr>






<div id="p1" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/DECOLORACION/DECOLORACION PRODUCTO1.png" >
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000">
    <h2><strong>Decolorante y Peróxido </strong> </h2>
    <p>Decoloración en poco tiempo, sin maltratar el cabello. Azul.
      500 grs. de decolorante.
      <br>  Peróxido en crema de 10 a 50 vol.
      </p>

  </div>
</div>

<hr>


<div id="p2" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center"  data-aos="flip-left"  data-aos-duration="1000" >
    <h2><strong>Decolorante y Peróxido </strong> </h2>
    <p>Decoloración en poco tiempo, sin maltratar el cabello. Azul.
      500 grs. de decolorante.
      <br> Peróxido en crema de 10 a 50 vol.
      </p>
  </div>
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/DECOLORACION/DECOLORACION PRODUCTO2.png" alt="">
  </div>
</div>
