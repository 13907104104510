<!-- NAV BAR -->
<nav class="navbar navbar-expand-lg navbar-light bg-light"> <!-- Bg light es el color de fondo del nav-->
    <div class="navbar-collapse collapse w-100 dual-collapse2 order-1 order-md-0">
        <ul class="navbar-nav ml-auto text-center">
            <li class="nav-item dropdown active mr-5">
                    <a class="am nav-link dropdown-toggle ntxt" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="wtnav">Productos </span>
                    </a>
            
                      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a class="dropdown-item" [routerLink]="['/Silikas']">Silicas</a>
                      <a class="dropdown-item" [routerLink]="['/Oleos']">Oleo's</a>
                      <a class="dropdown-item" [routerLink]="['/Naturales']">Naturales</a>
                      <a class="dropdown-item" [routerLink]="['/Matizadores']">Matizadores</a>
                      <a class="dropdown-item" [routerLink]="['/Decoloracion']">Decoloración</a>
                      <a class="dropdown-item" [routerLink]="['/Acondicionadores']">Tratamientos Acondicionadores</a>
                      <a class="dropdown-item" [routerLink]="['/GelesCapilares']">Geles Capilares</a>
                      <a class="dropdown-item" [routerLink]="['/FashionLine']">360° Fashion Line</a>
                      <a class="dropdown-item" [routerLink]="['/Shampoo']">Shampoos de salón</a>
                      <a class="dropdown-item" [routerLink]="['/Nicte']">Nicte-Ha</a>
                    </div>
                    
            </li>
            <li class="nav-item active mr-5">
                <a class="am nav-link" href="#qs"><span class="wtnav"> ¿Quiénes somos? </span> </a>
            </li>
        </ul>
    </div>
    <div class="mx-auto my-2 order-0 order-md-1 position-relative">
        <a class="mx-auto" href="#">
            <!-- <img class="mb-4" src="../../assets/images/LOGO VivitalITAL.png" width="140" height="140"> -->
            <img class="mb-4" src="../../assets/images/LOGO VIVITAL (2).png" width="110" height="73">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".dual-collapse2">
            <span class="navbar-toggler-icon"></span>
        </button>
    </div>
    <div class="navbar-collapse collapse w-100 dual-collapse2 order-2 order-md-2">
        <ul class="navbar-nav mr-auto text-center">
            <li class="qm nav-item active ml-5">
                <a class="nav-link" [routerLink]="['/distribuidores']"> <span class="wtnav">Distribuidores </span></a>
            </li>
            <li class="qm nav-item active ml-5">
                <a class="nav-link" [routerLink]="['/contacto']"> <span class="wtnav">Contacto</span></a>
            </li>
        </ul>

        <form [formGroup]="form" (ngSubmit)="submitBuscar()" class="form-inline my-2 my-lg-0">
            <input formControlName="pagina" class="form-control mr-sm-2 rounded-pill" type="search" placeholder="Buscar ..." aria-label="Search">
            <button type="submit" (ngSubmit)="submitBuscar()" class="btn btn-link text-primary"><i class="fa fa-search lupi"></i></button>
        </form>

    </div>
</nav>