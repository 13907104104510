<div>
  <img style="width: 100%; height: 100%;" src="../../assets/images/BANERS/BANNER NICTEAH.png">
</div>

<div class="card-deck mt-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NICTA/NICTEA PRODUCTO1.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Shampoo De Hueso De Mamey</h5>
      <a href="/Nicte#p1"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NICTA/NICTEA PRODUCTO2.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Shampoo De Jitomate</h5>
      <a href="/Nicte#p2"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NICTA/NICTEA PRODUCTO3.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Shampoo Con Cacahuananche, Colágeno Y Pro-Vitamina</h5>
      <a href="/Nicte#p3"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
</div>

<div class="card-deck mt-5 mb-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NICTA/NICTEA PRODUCTO4.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Shampoo De Arcilla Verde</h5>
      <a href="/Nicte#p4"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NICTA/NICTEA PRODUCTO5.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Shampoo Aceite De Oso y Colágeno</h5>
      <a href="/Nicte#p5"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NICTA/NICTEA PRODUCTO6.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Shampoo De Chile con Pantenol</h5>
      <a href="/Nicte#p6"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
</div>


<div class="card-deck mt-5 mb-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NICTA/NICTEA PRODUCTO7.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Shampoo De Sangre De Drago Y Espinosilla</h5>
      <a href="/Nicte#p7"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NICTA/NICTEA PRODUCTO8.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Arcilla rosa</h5>
      <a href="/Nicte#p8"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NICTA/NICTEA PRODUCTO9.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Arcilla verde</h5>
      <a href="/Nicte#p9"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
</div>



<div class="card-deck mt-5 mb-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NICTA/NICTEA PRODUCTO10.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Aceite de Oso</h5>
      <a href="/Nicte#p10"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NICTA/NICTEA PRODUCTO11.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Concha Nácar</h5>
      <a href="/Nicte#p11"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NICTA/NICTEA PRODUCTO12.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Crema Humectante</h5>
      <a href="/Nicte#p12"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
</div>



<div class="card-deck mt-5 mb-5 col-lg-8 col-md-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NICTA/NICTEA PRODUCTO13.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Mascarillas de arcillas rosa</h5>
      <a href="/Nicte#p13"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/NICTA/NICTEA PRODUCTO14.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Mascarillas de arcillas verde</h5>
      <a href="/Nicte#p14"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
</div>






<hr>






<div id="p1" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NICTA/NICTEA PRODUCTO1.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Shampoo De Hueso De Mamey</strong> </h2>
    <p>Para problemas de resequedad. Estimula el crecimiento y el grosor del cabello</p>

  </div>
</div>

<hr>

<div id="p2" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Shampoo De Jitomate </strong> </h2>
    <p>Por sus estractos naturales es astringente, exfoliante, refrescante y antibacterial. Humecta al cabello dejándolo
      sedoso. Estimula el creciemiento. </p>
  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NICTA/NICTEA PRODUCTO2.png" alt="">
  </div>
</div>

<hr>

<div id="p3" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NICTA/NICTEA PRODUCTO3.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Shampoo Con Cacahuananche, Colágeno Y Pro-Vitamina </strong> </h2>
    <p>Auxiliar en los problemas de caída del cabello. Dejándolo sedoso y fácil de peinar.</p>
  </div>
</div>

<hr>

<div id="p4" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Shampoo De Arcilla Verde</strong> </h2>
    <p>Anticéptico, purificante, reminaralizante, otorga suavidad, humecta y regenera. Excelente para combatir el
      cabello graso.</p>

  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NICTA/NICTEA PRODUCTO4.png" alt="">
  </div>
</div>

<hr>

<div id="p5" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NICTA/NICTEA PRODUCTO5.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Shampoo Aceite De Oso y Colágeno</strong> </h2>
    <p>Indicado para cabellos con problemas de ceborrea, orzuela y resequedad. Equilibra los requerimientos del cabello
      y cuero cabelludo.</p>
  </div>
</div>

<hr>

<div id="p6" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Shampoo De Chile con Pantenol</strong> </h2>
    <p>Auxiliar en el tratamiento de caída de cabello. Enriquecido con Provitaminas B5 nutriendo al cabello de la raíz a
      la punta.</p>

  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NICTA/NICTEA PRODUCTO6.png" alt="">
  </div>
</div>

<hr>

<div id="p7" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NICTA/NICTEA PRODUCTO7.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Shampoo De Sangre De Drago Y Espinosilla </strong> </h2>
    <p>Antiseborreico, Astringente y antiflamatorio. Excelente para cuero cabelludo sensible. </p>
  </div>
</div>

<hr>

<div id="p8" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Arcilla rosa</strong> </h2>
    <p>Elimina barros, espinillas, paño y manchas. Auxiliar en la eliminación de arrugas prematuras y cutis graso.
      Reactiva la función celular. Nutre, fortalece y revitaliza los tejidos de la piel. Ayuda a desaparecer estrías y
      celulitis</p>

  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NICTA/NICTEA PRODUCTO8.png" alt="">
  </div>
</div>

<hr>

<div id="p9" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NICTA/NICTEA PRODUCTO9.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Arcilla verde</strong> </h2>
    <p>Elimina barros, espinillas, paño y manchas. Auxiliar en la eliminación de arrugas prematuras y cutis graso.
      Reactiva la función celular. Nutre, fortalece y revitaliza los tejidos de la piel. Ayuda a desaparecer estrías y
      celulitis</p>
  </div>
</div>

<hr>

<div id="p10" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Aceite de Oso</strong> </h2>
    <p>El Aceite de Oso de Nicte-Ha, está hecho tratamiento, por lo que si se desea puede no enjuagarse.
      <br> Contiene los 3 Aceites Esenciales para ser llamado Aceite de Oso: Almendras, Ricino y Oliva</p>

  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NICTA/NICTEA PRODUCTO10.png" alt="">
  </div>
</div>



<div id="p11" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NICTA/NICTEA PRODUCTO11.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Concha Nácar</strong> </h2>
    <p>Quita manchas hechas por espinillas y barros.</p>
  </div>
</div>


<div id="p12" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Crema Humectante</strong> </h2>
    <p>Por su filtro solar evita el paño hecho por el sol, equilibra la humedad en el cutis permitiendo transpiración de
      la piel.
      <br> Base de maquillaje y tratamiento hipoalergénico.
    </p>
  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NICTA/NICTEA PRODUCTO12.png" alt="">
  </div>
</div>


<div id="p13" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NICTA/NICTEA PRODUCTO13.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Mascarillas de arcillas rosa</strong> </h2>
    <p>Elimina barros, espinillas, paño y manchas. Auxiliar en la eliminación de arrugas prematuras y cutis graso.
      Reactiva la función celular. Nutre, fortalece y revitaliza los tejidos de la piel. Ayuda a desaparecer estrías y
      celulitis</p>
  </div>
</div>


<div id="p14" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Mascarillas de arcillas verde</strong> </h2>
    <p>Elimina barros, espinillas, paño y manchas. Auxiliar en la eliminación de arrugas prematuras y cutis graso.
      Reactiva la función celular. Nutre, fortalece y revitaliza los tejidos de la piel. Ayuda a desaparecer estrías y
      celulitis</p>
  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/NICTA/NICTEA PRODUCTO14.png" alt="">
  </div>
</div>
