import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acondicionadores',
  templateUrl: './acondicionadores.component.html',
  styleUrls: ['./acondicionadores.component.css']
})
export class AcondicionadoresComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
