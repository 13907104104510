<!--Carrousel-->

<div id="demo" class="carousel slide" data-ride="carousel">
  <ul class="carousel-indicators">
    <li data-target="#demo" data-slide-to="0" class="active"></li>
    <li data-target="#demo" data-slide-to="1"></li>
    <li data-target="#demo" data-slide-to="2"></li>
  </ul>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="../../assets/images/BANERS/BANER PRINCIPAL.png">
    </div>
    <div class="carousel-item">
      <a [routerLink]="['/distribuidores']"><img src="../../assets/images/BANERS/BANER PRINCIPAL2.png"></a>
      <div class="carousel-caption2">
        <a [routerLink]="['/distribuidores']"><button class="btn btn-primary rounded-pill bmi"> Más
            información</button></a>
      </div>
    </div>
    <div class="carousel-item">
      <img src="../../assets/images/BANERS/BANNER PRODUCTOS.png">
    </div>
  </div>
  <a class="carousel-control-prev" href="#demo" data-slide="prev">
    <span class="carousel-control-prev-icon"></span>
  </a>
  <a class="carousel-control-next" href="#demo" data-slide="next">
    <span class="carousel-control-next-icon"></span>
  </a>
</div>
<!--Fin carrousel -->


<br><br>


<!-- Inicio Quines somos-->

<div class="row col-xs-12">
  <div class="col-md-12 mt-5">
    <h2 id="qs" style="color: #347dbb;" class="text-center" data-aos="fade-down" data-aos-duration="1000">
      ¿Quiénes Somos?
    </h2>
    <div class="row pqm mt-3">
      <div class="col-md-2"></div>
      <div class="col-md-4">
        <p class="ts" data-aos="fade-right" data-aos-duration="1000">
          Somos una Empresa Orgullosamente Hidrocálida. Nuestro trabajo inició en 1998 con el Esfuerzo Familiar y la
          Perseverancia. Poniendo siempre en primer lugar Alta Calidad y Buen Servicio. <br><br><br><br>
          <h3 style="color: #347dbb;" class="text-center" data-aos="fade-up" data-aos-duration="1000">#Vivital</h3>
      </div>
      <div class="col-md-6 dqm" data-aos="fade-left" data-aos-duration="1000">
        <img class="ims" src="../../assets/images/QuienesSomos.png" />
      </div>
    </div>
  </div>
</div>
<!-- Fin Quines somos-->

<br><br><br>



<!-- Inicio Mision, Valores, Vision -->

<div class="container text-center my-3" data-aos="flip-up" data-aos-duration="1000">

  <div class="row mx-auto my-auto">
    <div id="recipeCarousel" class="carousel slide w-100 carousel-fade" data-ride="carousel">
      <div class="carousel-inner w-100" role="listbox">
        <div class="carousel-item active">
          <div class="col-md-12">
            <div class="card card-body border-0 text-center">
              <h3 style="color: #347dbb;">Misión</h3>
              <p>Ser una Compañía Emprendedora e Innovadora en los productos de belleza. Trabajamos de manera conjunta
                con nuestros clientes y proveedores desarrollando productos para las necesidades y gustos en tendencia.
                La mejora continua es nuestro compromiso, todos los días en todas las áreas de nuestra empresa.
              </p>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="col-md-12">
            <div class="card card-body border-0 text-center">
              <h3 style="color: #347dbb;">Visión</h3>
              <p>Ser una Empresa Reconocida Nacionalmente
                como Líder para el Desarrollo de Estilistas
                en su Desempeño Diario.
                <br><br><br>
              </p>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="col-md-12">
            <div class="card card-body border-0 text-center">
              <h3 style="color: #347dbb;">Valores</h3>
              <p>Integridad, Honestidad, Responsabilidad,
                Humanidad y Cuidadosos de Nuestro Medio
                Ambiente.
                <br><br><br>
              </p>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="col-md-12">
            <div class="card card-body border-0 text-center">
              <h3 style="color: #347dbb;">Objetivo</h3>
              <p>Crear una Marca Veraz, Confiable y Creativa.
                <br><br><br>
              </p>
            </div>
          </div>
        </div>
      </div>

      <a class="carousel-control-prev w-auto" href="#recipeCarousel" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon bg-primary border border-dark rounded-circle" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next w-auto" href="#recipeCarousel" role="button" data-slide="next">
        <span class="carousel-control-next-icon bg-primary border border-dark rounded-circle" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</div>


<div>
  <img style="width: 100%; height: 100%;" src="../../assets/images/Valores.png">
</div>



<!-- Fin Mision/valores-->





<!--
<div id="demos" class="carousel slide" data-ride="carousel">
  <ul class="carousel-indicators">
    <li data-target="#demos" data-slide-to="0" class="active"></li>
    <li data-target="#demos" data-slide-to="1"></li>
    <li data-target="#demos" data-slide-to="2"></li>
  </ul>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="../../assets/images/Valores.png"> 
      <div class="carousel-captionv">
        <h3>MISION</h3>
        <br>
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla, voluptatem quo earum modi beatae corporis? Laborum perspiciatis aperiam deserunt. Numquam veniam distinctio exercitationem non, nulla necessitatibus consequatur laborum eos quam! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo soluta inventore placeat veniam totam, voluptas magni ab asperiores in voluptate quod illum, natus harum officiis expedita! Pariatur debitis ad tenetur.</p>
      </div>   
    </div>
    <div class="carousel-item">
      <img src="../../assets/images/Valores.png" >
          <div class="carousel-captionv">
          <h3>VISION</h3>
          <br>
          <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla, voluptatem quo earum modi beatae corporis? Laborum perspiciatis aperiam deserunt. Numquam veniam distinctio exercitationem non, nulla necessitatibus consequatur laborum eos quam! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo soluta inventore placeat veniam totam, voluptas magni ab asperiores in voluptate quod illum, natus harum officiis expedita! Pariatur debitis ad tenetur.</p>
        </div>
    </div>
    <div class="carousel-item">
      <img src="../../assets/images/Valores.png"> 
      <div class="carousel-captionv">
        <h3>VALORES</h3>
        <br>
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla, voluptatem quo earum modi beatae corporis? Laborum perspiciatis aperiam deserunt. Numquam veniam distinctio exercitationem non, nulla necessitatibus consequatur laborum eos quam! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo soluta inventore placeat veniam totam, voluptas magni ab asperiores in voluptate quod illum, natus harum officiis expedita! Pariatur debitis ad tenetur.</p>
      </div>   
    </div>
  </div>
  <a class="carousel-control-prev" href="#demos" data-slide="prev">
    <span class="carousel-control-prev-icon"></span>
  </a>
  <a class="carousel-control-next" href="#demos" data-slide="next">
    <span class="carousel-control-next-icon"></span>
  </a>
</div>
-->





<!-- TimeLine 
<div class="container">
  <h2 id="qs" style="color: #347dbb;" class="text-center" data-aos="fade-down" data-aos-duration="2000"> Conócenos </h2>
  <div class="row">
    <div class="col-md-12 mt-5 mb-5">
      <div class="main-timeline5" data-aos="fade-right" data-aos-duration="1000">
        <div class="timeline">
          <div class="timeline-icon"><span class="year">Mision</span></div>
          <div class="timeline-content">
            <h3 class="title">Web Desginer</h3>
            <p class="description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacinia mi ultrices, luctus nunc ut,
              commodo enim. Vivitalamus sem erat.
            </p>
          </div>
        </div>

        <div class="timeline" data-aos="fade-left" data-aos-duration="1000">
          <div class="timeline-icon"><span class="year">Vision</span></div>
          <div class="timeline-content">
            <h3 class="title">Web Desginer</h3>
            <p class="description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacinia mi ultrices, luctus nunc ut,
              commodo enim. Vivitalamus sem erat.
            </p>
          </div>
        </div>
        <div class="timeline" data-aos="fade-left" data-aos-duration="1000">
          <div class="timeline-icon"><span class="year">Valores</span></div>
          <div class="timeline-content">
            <h3 class="title">Web Developer</h3>
            <p class="description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacinia mi ultrices, luctus nunc ut,
              commodo enim. Vivitalamus sem erat.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
-->






<!-- Inicio Mision/valores

<div class="mx-auto">
  
    <div id="carouselContent" class="col-md-4 carousel slide text-center" data-ride="carousel">
      <div class="carousel-inner" role="listbox">
        <div class="carousel-item active text-center p-4">
          <h3>Mision</h3>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptas, dolorum nemo itaque cum saepe eaque ea pariatur molestiae in explicabo quibusdam molestias quidem ullam repellat magnam earum dicta tenetur maiores.Fuga libero atque magni delectus velit saepe modi enim! Debitis sint temporibus deleniti, culpa ipsum, est sed voluptates ipsam maiores voluptatem labore neque iusto nesciunt beatae, laboriosam ex dolore amet.</p>
        </div>
        <div class="carousel-item text-center p-4">
          <h3>Vision</h3>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptas, dolorum nemo itaque cum saepe eaque ea pariatur molestiae in explicabo quibusdam molestias quidem ullam repellat magnam earum dicta tenetur maiores.Fuga libero atque magni delectus velit saepe modi enim! Debitis sint temporibus deleniti, culpa ipsum, est sed voluptates ipsam maiores voluptatem labore neque iusto nesciunt beatae, laboriosam ex dolore amet.</p>  
        </div>
        <div class="carousel-item text-center p-4">
          <h3>Valores</h3>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptas, dolorum nemo itaque cum saepe eaque ea pariatur molestiae in explicabo quibusdam molestias quidem ullam repellat magnam earum dicta tenetur maiores.Fuga libero atque magni delectus velit saepe modi enim! Debitis sint temporibus deleniti, culpa ipsum, est sed voluptates ipsam maiores voluptatem labore neque iusto nesciunt beatae, laboriosam ex dolore amet.</p>
        </div>
      </div>
      <a style="color: #347dbb;" class="carousel-control-prev" href="#carouselContent" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselContent" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  
</div>

<div>
  <img style="width: 100%; height: 100%;" src="../../assets/images/Valores.png">
</div>

-->



<div>
  <img style="width: 100%; height: 100%;" src="../../assets/images/BANERS/BANNER EXPERIENCIAS.png">
</div>


<div class="text-center mt-5">
  <iframe class="ifra" src="https://www.youtube.com/embed/sqIlFIsEv4U" frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen></iframe>
</div>


<div class="mx-auto col-7 mt-5">
  <h4 style="color: #347dbb;" class="mt-5 text-center">Karla del Real Salón</h4>
  <p class="mt-3" style="text-align: justify;">En esta ocasión les presentamos a una de nuestras distribuidoras <span
      style="color: #347dbb;"> #vivital </span>
    en Aguascalientes. Te invitamos a que la visites y conozcas las maravillas que es capaz de hacer con nuestras
    diferentes líneas de productos para el cabello. Somos los profesionales en el cuidado de tu cabello.
  </p>
</div>

<br><br>

<div class="text-center mt-5">
  <iframe class="ifra" src="https://www.youtube.com/embed/5rU4HQjGn3k" frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen></iframe>
</div>

<div class="mx-auto col-7 mt-5 mb-4">
  <h4 style="color: #347dbb;" class="mt-5 text-center">Estética Lucy</h4>
  <p class="mt-3" style="text-align: justify;">Los introducimos con una de nuestras distribuidoras <span
      style="color: #347dbb;">#vivital </span> , ubicada en
    Aguascalientes. Y tú, ¿ya formas parte de la experiencia vivital?, ¿Qué estas esperando?, contamos con una gran
    variedad de productos que harán de tu cabello, algo inolvidable. Somos los profesionales en el cuidado de tu
    cabello.
  </p>
</div>


<div>
  <img style="width: 100%; height: 100%;" src="../../assets/images/BANERS/BANNER CATALOGO.png">
</div>

<div class="text-center mt-5 mb-5" data-aos="fade-up">
  <a href="../../assets/pdf/Catálogo Vivital 2021.pdf" download="catalogo2020">
    <button class="btn btn-primary rounded-pill btn-lg"> Descargar Catálogo</button>
  </a>
</div>
