<!--
<div>
    <img style="width: 100%; height: 100%;" src="../../assets/images/BANERS/BANNER PRODUCTOS 360 FASHION LINE.png">
  </div>
  
  <div class="card-deck mt-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/FASHION/360 PRODUCTO1.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Producto 1</h5>
        <h6>$200</h6>
        <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
          data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"> Ver más </button>
        <div class="collapse" id="collapseExample">
          <div class="card card-body border-0 ct">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
          </div>
        </div>
      </div>
    </div>
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/FASHION/360 PRODUCTO2.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Producto 2</h5>
        <h6>$200</h6>
        <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
          data-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample2"> Ver más </button>
        <div class="collapse" id="collapseExample2">
          <div class="card card-body border-0 ct">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
          </div>
        </div>
      </div>
    </div>
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/FASHION/360 PRODUCTO3.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Producto 3</h5>
        <h6>$200</h6>
        <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
          data-target="#collapseExample3" aria-expanded="false" aria-controls="collapseExample3"> Ver más </button>
        <div class="collapse" id="collapseExample3">
          <div class="card card-body border-0 ct">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="card-deck mt-5 mb-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/FASHION/360 PRODUCTO4.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Producto 4</h5>
        <h6>$200</h6>
        <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
          data-target="#collapseExample4" aria-expanded="false" aria-controls="collapseExample4"> Ver más </button>
        <div class="collapse" id="collapseExample4">
          <div class="card card-body border-0 ct">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
          </div>
        </div>
      </div>
    </div>
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/FASHION/360 PRODUCTO5.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Producto 5</h5>
        <h6>$200</h6>
        <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
          data-target="#collapseExample5" aria-expanded="false" aria-controls="collapseExample5"> Ver más </button>
        <div class="collapse" id="collapseExample5">
          <div class="card card-body border-0 ct">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
          </div>
        </div>
      </div>
    </div>
    <div class="card border-0">
      <div class="inner">
        <img class="card-img-top" src="../../assets/images/FASHION/360 PRODUCTO6.png" alt="Card image cap">
      </div>
      <div class="card-body">
        <h5 class="card-title">Producto 6</h5>
        <h5>$200</h5>
        <button class="btn btn-primary rounded-pill text-center" type="button" data-toggle="collapse"
          data-target="#collapseExample6" aria-expanded="false" aria-controls="collapseExample6"> Ver más </button>
        <div class="collapse" id="collapseExample6">
          <div class="card card-body border-0 ct">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
          </div>
        </div>
      </div>
    </div>
  
  </div>
-->


<div>
  <img style="width: 100%; height: 100%;" src="../../assets/images/BANERS/BANNER PRODUCTOS 360 FASHION LINE.png">
</div>

<div class="card-deck mt-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/FASHION/360 PRODUCTO1.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Moulder shine</h5>
      <a href="/FashionLine#p1"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/FASHION/360 PRODUCTO2.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Moulder Mousse</h5>
      <a href="/FashionLine#p2"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/FASHION/360 PRODUCTO3.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Moulder lassio</h5>
      <a href="/FashionLine#p3"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
</div>

<div class="card-deck mt-5 mb-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/FASHION/360 PRODUCTO4.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Moulder gel</h5>
      <a href="/FashionLine#p4"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/FASHION/360 PRODUCTO5.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Modeling wax</h5>
      <a href="/FashionLine#p5"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/FASHION/360 PRODUCTO6.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Desmanchador de tinte en piel</h5>
      <a href="/FashionLine#p6"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>

</div>






<hr>






<div id="p1" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/FASHION/360 PRODUCTO1.png">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Moulder shine</strong> </h2>
    <p>Spray Abrillantador con las más Alta Calidad en su Feniltrimeticona. Por su Gran Calidad de Dimeticonas
      acondiciona el cabello Sin residuos
      <br> Producto Térmico
    </p>

  </div>
</div>

<hr>


<div id="p2" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Moulder Mousse</strong> </h2>
    <ul>
      <li>Otorga brillo y apariencia de humedad</li>
      <li>El primero en contener acondicionadores</li>
      <li>Conserva el cuerpo del cabello y refina los rizos </li>
      <li>Térmico y no maltrata el cabello</li>
      <li>Es el único mousse en el mercado sin dejar residuos ni ser pegajoso</li>
      <li>No reseca</li>
    </ul>
  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/FASHION/360 PRODUCTO2.png" alt="">
  </div>
</div>

<hr>

<div id="p3" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/FASHION/360 PRODUCTO3.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Moulder lassio</strong> </h2>
    <p>Vivital 360 ha revolucionado el concepto de alaciado express, ya que hemos creado una fórmula con
      acondicionadores, los cuales permiten sea térmico sin maltratar el cuerpo del cabello. Sin dejar frezze y
      otorgando brillo, no deja residuos. Excelente para dar acabados de alto estilismo.</p>
  </div>
</div>

<hr>

<div id="p4" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Moulder gel</strong> </h2>
    <ul>
      <li>Incrementa el volumen del alto peinado</li>
      <li>Crea rizos con fuerza sin endurecerlos</li>
      <li>Da apariencia natural </li>
      <li>No deja residuos ni grumos</li>
      <li>Producto térmico e hipoalergénico</li>
      <li>Otorga movimiento a los peinados</li>
    </ul>

  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/FASHION/360 PRODUCTO4.png" alt="">
  </div>
</div>

<hr>


<div id="p5" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/FASHION/360 PRODUCTO5.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Modeling wax</strong> </h2>
    <ul>
      <li>Libre de alcohol</li>
      <li>Aspecto húmedo</li>
      <li>Óptimo para moldear y realizar peinados tipo “Style” </li>
    </ul>
  </div>
</div>

<hr>


<div id="p6" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Desmanchador de tinte en piel</strong> </h2>
    <p>Retira las manchas del tinte en crema en la piel de la cara, cuello, orejas, brazos y manos.
      De manera rápida sin lastimar
    </p>

  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/FASHION/360 PRODUCTO6.png" alt="">
  </div>
</div>
