import { ShampoosComponent } from './shampoos/shampoos.component';
import { NicTeaComponent } from './nic-tea/nic-tea.component';
import { GelesComponent } from './geles/geles.component';
import { FashionComponent } from './fashion/fashion.component';
import { AcondicionadoresComponent } from './acondicionadores/acondicionadores.component';
import { DecoloracionComponent } from './decoloracion/decoloracion.component';
import { MatizadoresComponent } from './matizadores/matizadores.component';
import { NaturalesComponent } from './naturales/naturales.component';
import { OleosComponent } from './oleos/oleos.component';
import { SilikasComponent } from './silikas/silikas.component';
import { DistribuidoresComponent } from './distribuidores/distribuidores.component';
import { ProductosComponent } from './productos/productos.component';
import { HomeComponent } from './home/home.component';
import { ContactoComponent } from './contacto/contacto.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'distribuidores', component: DistribuidoresComponent},
  {path: 'contacto', component: ContactoComponent },
  {path: 'productos', component: ProductosComponent},
  {path: 'Silikas', component: SilikasComponent},
  {path: 'Oleos', component: OleosComponent},
  {path: 'Naturales', component: NaturalesComponent},
  {path: 'Matizadores', component: MatizadoresComponent},
  {path: 'Decoloracion', component: DecoloracionComponent},
  {path: 'Acondicionadores', component: AcondicionadoresComponent},
  {path: 'FashionLine', component: FashionComponent},
  {path: 'GelesCapilares', component: GelesComponent},
  {path: 'Nicte', component: NicTeaComponent},
  {path: 'Shampoo', component: ShampoosComponent},
  {path: '**', pathMatch: 'full', redirectTo: 'home'}
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: "ignore",
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
