<div>
  <img style="width: 100%; height: 100%;" src="../../assets/images/BANERS/BANNER PRODUCTOS SILIKAS.png">
</div>

<div class="card-deck mt-5 col-12 text-center">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/SILKAS/SILIKAS PRODUCTO1.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Silik & ends</h5>
      <a href="/Silikas#p1"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/SILKAS/SILIKAS PRODUCTO2.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Silik citrus </h5>
      <a href="/Silikas#p2"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/SILKAS/SILIKAS PRODUCTO3.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Silik citrus </h5>
      <a href="/Silikas#p3"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
</div>

<div class="card-deck mt-5 mb-5 col-lg-8 col-md-12 text-center">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/SILKAS/SILIKAS PRODUCTO4.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Seda Cocoon</h5>
      <a href="/Silikas#p4"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/SILKAS/SILIKAS PRODUCTO5.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Seda Arándano</h5>
      <a href="/Silikas#p5"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
</div>



<hr>






<div id="p1" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SILKAS/SILIKAS PRODUCTO1.png" >
  </div>
  <div class="col-sm-5" data-aos="flip-right"  data-aos-duration="1000">
    <h2 class="text-center"><strong>Silik & ends</strong> </h2>
    <ul>
      <li>Restaura puntas abiertas</li>
      <li>Auxiliar para planchar</li>   
      <li>Moldeador / abrillantador</li>
      <li>Desenredante</li>
    </ul>
  </div>
</div>

<hr>


<div id="p2" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000" >
    <h2><strong>Silik citrus </strong> </h2>
    <ul>
      <li>Restaura puntas abiertas</li>
      <li>Auxiliar para planchar</li>   
      <li>Moldeador / abrillantador</li>
      <li>Desenredante</li>
    </ul>
  </div>
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SILKAS/SILIKAS PRODUCTO2.png" alt="">
  </div>
</div>

<hr>

<div id="p3" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SILKAS/SILIKAS PRODUCTO3.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000">
    <h2><strong>Silik citrus </strong> </h2>
    <ul>
      <li>Restaura puntas abiertas</li>
      <li>Auxiliar para planchar</li>   
      <li>Moldeador / abrillantador</li>
      <li>Desenredante</li>
    </ul>
  </div>
</div>

<hr>

<div id="p4" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000">
    <h2><strong>Seda Cocoon</strong> </h2>
    <ul>
      <li>Sella puntas</li>
      <li>Por su protector térmico, es ideal para planchar y moldear.</li>   
      <li>Da brillo</li>
      <li>Contiene Vitamina “E”</li>
    </ul>
  </div>
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SILKAS/SILIKAS PRODUCTO4.png" alt="">
  </div>
</div>

<hr>


<div id="p5" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left"  data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SILKAS/SILIKAS PRODUCTO5.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right"  data-aos-duration="1000">
    <h2><strong>Seda Arándano</strong> </h2>
    <ul>
      <li>Sella puntas</li>
      <li>Por su protector térmico, es ideal para planchar y moldear.</li>   
      <li>Da brillo</li>
      <li>Contiene Vitamina “E”</li>
    </ul>  
  </div>
</div>

