<div>
  <img style="width: 100%; height: 100%;" src="../../assets/images/BANERS/BANNER PRODUCTOS SHAMPOOS DE SALON.png">
</div>

<div class="card-deck mt-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/SHAMPOO/SHAMPOO PRODUCTO1.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Shampoo Revitalizante</h5>
      <a href="/Shampoo#p1"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/SHAMPOO/SHAMPOO PRODUCTO2.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Shampoo De Keratina-Colageno</h5>
      <a href="/Shampoo#p2"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/SHAMPOO/SHAMPOO PRODUCTO3.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Shampoo de Limpieza Profunda </h5>
      <a href="/Shampoo#p3"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
</div>

<div class="card-deck mt-5 mb-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/SHAMPOO/SHAMPOO PRODUCTO4.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Shampoo de Limpieza Profunda</h5>
      <a href="/Shampoo#p4"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/SHAMPOO/SHAMPOO PRODUCTO5.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Shampoo Cabellos Teñidos</h5>
      <a href="/Shampoo#p5"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/SHAMPOO/SHAMPOO PRODUCTO6.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Shampoo Exentric</h5>
      <a href="/Shampoo#p6"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
</div>


<div class="card-deck mt-5 mb-5 col-12 text-center" data-aos="zoom-in" data-aos-duration="1000">
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/SHAMPOO/SHAMPOO PRODUCTO7.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Acondicionador</h5>
      <a href="/Shampoo#p7"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/SHAMPOO/SHAMPOO PRODUCTO8.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Shampoo Herbal</h5>
      <a href="/Shampoo#p8"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
  <div class="card border-0">
    <div class="inner">
      <img class="card-img-top" src="../../assets/images/SHAMPOO/SHAMPOO PRODUCTO9.png" alt="Card image cap">
    </div>
    <div class="card-body">
      <h5 class="card-title">Shampoo Neutro</h5>
      <a href="/Shampoo#p9"><button class="btn btn-primary rounded-pill text-center" type="button"> Ver más
        </button></a>
    </div>
  </div>
</div>



<hr>






<div id="p1" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SHAMPOO/SHAMPOO PRODUCTO1.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Shampoo Revitalizante</strong> </h2>
    <p>Excelente para cabello maltratado, eliminando resequedad y da vitalidad gracias a sus extractos naturales y
      vitamina E. </p>

  </div>
</div>

<hr>

<div id="p2" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Shampoo De Keratina-Colageno</strong> </h2>
    <p>El Innovador e Increíble Shampoo de Keratina-Colágeno, es único en su tipo, contiene 5 Acondicionadores, los
      cuales interactúan entre sí para darle al cabello un TOTAL enriquecimiento otorgando una Suavidad Extrema y
      eliminando Frezze. Sus Activos Trabajan desde la Primera Aplicación evitando el enredo y ayudando a cerrar la
      cutícula. </p>
  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SHAMPOO/SHAMPOO PRODUCTO2.png" alt="">
  </div>
</div>

<hr>

<div id="p3" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SHAMPOO/SHAMPOO PRODUCTO3.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Shampoo de Limpieza Profunda </strong> </h2>
    <p>Contiene una EXCLUSIVA FÓRMULA Reforzada para Eliminar residuos En cabello como Lacas, cremas, suciedad de Medio
      Ambiente, sarro, entre otras impurezas. Es Suave Con el Cabello y otorga una sensación de Frescura Y Humectación
      Balanceada para dejarlo fácil de manejar. </p>
  </div>
</div>

<hr>

<div id="p4" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Shampoo de Limpieza Profunda</strong> </h2>
    <p>Contiene una EXCLUSIVA FÓRMULA Reforzada para Eliminar residuos En cabello como Lacas, cremas, suciedad de Medio
      Ambiente, sarro, entre otras impurezas. Es Suave Con el Cabello y otorga una sensación de Frescura Y Humectación
      Balanceada para dejarlo fácil de manejar.</p>

  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SHAMPOO/SHAMPOO PRODUCTO4.png" alt="">
  </div>
</div>

<hr>

<div id="p5" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SHAMPOO/SHAMPOO PRODUCTO5.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Shampoo Cabellos Teñidos</strong> </h2>
    <p>Protege al cabello de los elementos químicos del tinte y ayuda a conservar más el color, pues cierra cutícula
      gracias a su colágeno y extractos naturales. </p>
  </div>
</div>

<hr>

<div id="p6" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Shampoo Exentric</strong> </h2>
    <p>Creado para las necesidades del hombre, con fragancia varonil y elementos anticaída, dando sedosidad a cualquier
      tipo de cabello.</p>

  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SHAMPOO/SHAMPOO PRODUCTO6.png" alt="">
  </div>
</div>

<hr>

<div id="p7" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SHAMPOO/SHAMPOO PRODUCTO7.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Acondicionador</strong> </h2>
    <p>Limpia y humecta el cabello, dejándolo suave para trabajar en él. </p>
  </div>
</div>

<hr>

<div id="p8" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Shampoo Herbal</strong> </h2>
    <p>Limpia y humecta el cabello, dejándolo suave para trabajar en él. </p>

  </div>
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SHAMPOO/SHAMPOO PRODUCTO8.png" alt="">
  </div>
</div>

<hr>

<div id="p9" class="row justify-content-center align-items-center m-5">
  <div class="col-sm-5" data-aos="flip-left" data-aos-duration="1000">
    <img style=" max-width: 100%; max-height: 100%;" src="../../assets/images/SHAMPOO/SHAMPOO PRODUCTO9.png" alt="">
  </div>
  <div class="col-sm-5 text-center" data-aos="flip-right" data-aos-duration="1000">
    <h2><strong>Shampoo Neutro</strong> </h2>
    <p>Limpia y humecta el cabello, dejándolo suave para trabajar en él. </p>
  </div>
</div>
